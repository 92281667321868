import { FourOhFour } from "ui/dist/components/FourOhFour";
import { AppLayout } from "../components/AppLayout";

function FourOhFourPage() {
    return <FourOhFour />;
}

// eslint-disable-next-line react/display-name
FourOhFourPage.getLayout = (page: React.ReactNode) => (
    <AppLayout contained>{page}</AppLayout>
);

export default FourOhFourPage;
